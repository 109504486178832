import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  marinleft: {
    marginLeft: theme.spacing(5),
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  }),
  subMessageBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subMessage: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: 0,
    color: theme.palette.secondary.light,
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});
