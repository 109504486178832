import React, { useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import root from 'window-or-global';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShredder,
  faHandPaper,
  faLineHeight,
  faCalendarCheck,
} from '@fortawesome/pro-light-svg-icons';
import useStyles from './styles';

const SubscriptionFactory = loadable(() => import('components/subscription-factory'));

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const List = loadable(() => import('@material-ui/core/List'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemIcon'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));

const SubscriptionView = ({ pricingModels }) => {
  const styles = useStyles();

  const [selected, setSelected] = useState('');

  return (
    <>
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Paper className={styles.paper} elevation={4}>
          <List>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faShredder} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText primary={
                <Typography variant="h6" component="p">
                 {'There\'s no contract'}
                </Typography>
              } />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faHandPaper} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText primary={
                <Typography variant="h6" component="p">
                  You can cancel anytime and there are no hidden fees
                </Typography>
              } />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faLineHeight} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText primary={
                <Typography variant="h6" component="p">
                 Upgrade or downgrade at any time
                </Typography>
              } />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCalendarCheck} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText primary={
                <Typography variant="h6" component="p">
                 {'We\'ll automatically renew your subscription for you every month'}
                </Typography>
              } />
            </ListItem>
          </List>
        </Paper>

    </Grid>
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="subscription-scroll-anchor"
      >
      <Grid item md={10} sm={12} xs={12}>
        <div className={styles.content}>
          <SubscriptionFactory{...{
            selected,
            onExpand: (val) => {
              setSelected(val);
              const elmnt = root.document.getElementById('subscription-scroll-anchor');
              elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            },
            models: pricingModels,
          }}/>
      </div>
    </Grid>
    </Grid>
  </>
  );
};

SubscriptionView.propTypes = {
  pricingModels: object,
};

export default SubscriptionView;
